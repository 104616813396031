"use client";

import { useAtomValue } from "jotai";
import { heroNftsAtom } from "@/app/atoms/heroNFTs";
import { Hexagon } from "./Hexagon";
import { usePathname } from "next/navigation";
import useMediaQuery from "@/app/hooks/useMediaQuery";

const HexGallery: React.FC = () => {
  const heroes = useAtomValue(heroNftsAtom);
  const pathname = usePathname();

  const isHomeRoute = pathname === "/";
  const isMaxMid = useMediaQuery("(max-width: 945px)");

  return (
    <div className="fixed top-0 left-[16vw] min-w-[1920px] min-h-[1100px]">
      <Hexagon positionClass="col1row1" hidden />
      <Hexagon
        positionClass="col1row2"
        useGradientBorder
        gradientDirection="bottom-top"
      />
      <Hexagon
        positionClass="col1row3"
        useGradientBorder
        gradientDirection="bottom-top"
      />
      <Hexagon positionClass="col1row4" />
      <Hexagon positionClass="col2row1" />
      <Hexagon
        positionClass="col2row2"
        useGradientBorder
        gradientDirection="right-left"
        image={isHomeRoute && isMaxMid ? heroes[11]?.image : undefined}
      />
      <Hexagon
        positionClass="col2row3"
        className="flex mid:hidden"
        image={isHomeRoute && isMaxMid ? heroes[10]?.image : undefined}
      />
      <Hexagon positionClass="col2row4" />
      <Hexagon
        positionClass="col3row1"
        useGradientBorder
        gradientDirection="left-right"
        className="hidden sml:flex"
        image={isHomeRoute && isMaxMid ? heroes[9]?.image : undefined}
      />
      <Hexagon
        positionClass="col3row2"
        hidden
        image={isHomeRoute && isMaxMid ? heroes[8]?.image : undefined}
      />
      <Hexagon
        positionClass="col3row3"
        image={isHomeRoute && isMaxMid ? heroes[7]?.image : undefined}
      />
      <Hexagon
        positionClass="col3row4"
        image={isHomeRoute ? heroes[1]?.image : undefined}
      />
      <Hexagon positionClass="col4row1" />
      <Hexagon
        positionClass="col4row2"
        useGradientBorder
        gradientDirection="left-right"
      />
      <Hexagon
        positionClass="col4row3"
        useGradientBorder
        gradientDirection="top-bottom"
      />
      <Hexagon
        positionClass="col4row4"
        image={isHomeRoute ? heroes[2]?.image : undefined}
      />
      <Hexagon
        positionClass="col5row1"
        useGradientBorder
        gradientDirection="right-left"
      />
      <Hexagon
        positionClass="col5row2"
        useGradientBorder
        gradientDirection="left-right"
        image={isHomeRoute ? heroes[3]?.image : undefined}
      />
      <Hexagon
        positionClass="col5row3"
        image={isHomeRoute ? heroes[4]?.image : undefined}
      />
      <Hexagon
        positionClass="col5row4"
        image={isHomeRoute ? heroes[5]?.image : undefined}
      />
      <Hexagon positionClass="col6row1" />
      <Hexagon
        positionClass="col6row2"
        image={isHomeRoute ? heroes[6]?.image : undefined}
      />
      <Hexagon
        positionClass="col6row3"
        image={isHomeRoute ? heroes[7]?.image : undefined}
      />
      <Hexagon positionClass="col6row4" />
      <Hexagon
        positionClass="col7row1"
        useGradientBorder
        gradientDirection="top-bottom"
      />
      <Hexagon
        positionClass="col7row2"
        image={isHomeRoute ? heroes[8]?.image : undefined}
      />
      <Hexagon
        positionClass="col7row3"
        image={isHomeRoute ? heroes[9]?.image : undefined}
      />
      <Hexagon
        positionClass="col7row4"
        image={isHomeRoute ? heroes[10]?.image : undefined}
      />
      <Hexagon
        positionClass="col8row1"
        image={isHomeRoute ? heroes[11]?.image : undefined}
      />
      <Hexagon positionClass="col8row2" />
      <Hexagon
        positionClass="col8row3"
        image={isHomeRoute ? heroes[0]?.image : undefined}
      />
      <Hexagon positionClass="col8row4" />
    </div>
  );
};

export default HexGallery;
