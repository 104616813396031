"use client";

import React, { useId } from "react";
import Image from "next/image";

interface HexagonProps {
  image?: string;
  positionClass: string;
  borderOpacity?: number;
  borderColor?: string;
  shadow?: string;
  useGradientBorder?: boolean;
  gradientDirection?: "top-bottom" | "left-right" | "bottom-top" | "right-left";
  hidden?: boolean;
  className?: string;
}

export const Hexagon: React.FC<HexagonProps> = ({
  image,
  positionClass,
  borderOpacity = 0.25,
  borderColor = "rgba(255, 255, 255, 0.5)",
  shadow = "",
  useGradientBorder = false,
  gradientDirection = "top-bottom",
  hidden = false,
  className = "",
}) => {
  const uniqueId = useId();

  const gradientDirectionMap: Record<
    string,
    { x1: string; y1: string; x2: string; y2: string }
  > = {
    "top-bottom": { x1: "0%", y1: "0%", x2: "0%", y2: "100%" },
    "left-right": { x1: "0%", y1: "0%", x2: "100%", y2: "0%" },
    "bottom-top": { x1: "0%", y1: "100%", x2: "0%", y2: "0%" },
    "right-left": { x1: "100%", y1: "0%", x2: "0%", y2: "0%" },
  };

  const { x1, y1, x2, y2 } = gradientDirectionMap[gradientDirection];

  return (
    <div
      className={`hex ${positionClass} ${className}`}
      style={{ opacity: hidden ? 0 : 1 }}
    >
      <svg
        width="375"
        height="325"
        viewBox="0 0 375 325"
        xmlns="http://www.w3.org/2000/svg"
        className="hex-svg"
      >
        <defs>
          <clipPath id={`hexagonClip-${uniqueId}`}>
            <polygon points="93.75,0 281.25,0 375,162.5 281.25,325 93.75,325 0,162.5" />
          </clipPath>
          {useGradientBorder && (
            <linearGradient
              id={`gradient-${uniqueId}`}
              x1={x1}
              y1={y1}
              x2={x2}
              y2={y2}
            >
              <stop
                offset="0%"
                style={{ stopColor: "white", stopOpacity: 0 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "white", stopOpacity: 0.5 }}
              />
            </linearGradient>
          )}
          {shadow && (
            <filter
              id={`shadow-${uniqueId}`}
              x="-50%"
              y="-50%"
              width="200%"
              height="200%"
            >
              <feDropShadow
                dx="5"
                dy="5"
                stdDeviation="10"
                floodColor={shadow}
              />
            </filter>
          )}
        </defs>
        <polygon
          points="93.75,0 281.25,0 375,162.5 281.25,325 93.75,325 0,162.5"
          fill="none"
          stroke={
            useGradientBorder ? `url(#gradient-${uniqueId})` : borderColor
          }
          strokeWidth="2"
          style={{
            opacity: borderOpacity,
          }}
          className="transition-colors duration-200"
          filter={shadow ? `url(#shadow-${uniqueId})` : "none"}
        />
        {image && (
          <foreignObject
            x="0"
            y="0"
            width="100%"
            height="100%"
            clipPath={`url(#hexagonClip-${uniqueId})`}
          >
            <div className="w-full h-full relative hex-image">
              <Image
                src={image}
                fill
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                style={{ objectFit: "cover" }}
                alt="hexagon image"
                priority
              />
            </div>
          </foreignObject>
        )}
      </svg>
    </div>
  );
};
