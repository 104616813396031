"use client";

import React, { useEffect, useState } from "react";
import HexGallery from "@/app/components/Hexagons/HexGallery";
import { cookieToInitialState } from "wagmi";
import { config } from "@/app/connection/config";

type State = ReturnType<typeof cookieToInitialState>;

export default function Home() {
  const [initialState, setInitialState] = useState<State | null>(null);

  useEffect(() => {
    const cookie = document.cookie;
    const initialState = cookieToInitialState(config, cookie);
    setInitialState(initialState);
    window.scrollTo(0, 0);
  }, []);

  if (!initialState) return null;

  return (
    <main className="overflow-hidden relative bg-[#1D233C]">
      <HexGallery />
    </main>
  );
}
